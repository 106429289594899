<template>
  <FullScreenLoader :isVisible="intialloading" />
  <div class="container">
    <SearchBar v-model="searchQuery" />
    <FilterOptions />
    <!-- <ImageCarousel :products="_prods" /> -->
    <ProductGrid
      :products="filteredProducts"
      :loading="loading"
      :showslider="true"
      @load-more="loadMoreProducts"
      @add-to-collection="onAddToCollection"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import SearchBar from "@/components/SearchBar.vue";
import FilterOptions from "@/components/FilterOptions.vue";
import ProductGrid from "@/components/ProductGrid.vue";
import { ProductService } from "@/services/productservice";
import { useFilterStore } from "@/stores/filterstore.js";
import { URLService } from "@/services/_url";
import FullScreenLoader from "@/components/common/FullScreenLoader.vue";
import debounce from "lodash/debounce";
// import ImageCarousel from "@/components/home/ImageCarousel.vue";

const page = ref(1);
const filterStore = useFilterStore();

// const _prods = ref([
//   {'image': './300_400.png'},
//   {'image': './300_400.png'},
//   {'image': './300_400.png'},
//   {'image': './300_400.png'},
//   {'image': './300_400.png'},
// ])

const searchQuery = ref("");
const products = ref([
  // { id: 1, name: 'Summer Dress', price: '$29.99', image: 'https://via.placeholder.com/300x400' },
  // { id: 2, name: 'Casual Shirt', price: '$39.99', image: 'https://via.placeholder.com/300x400' },
  // { id: 3, name: 'Jeans', price: '$49.99', image: 'https://via.placeholder.com/300x400' },
  // { id: 4, name: 'Sneakers', price: '$59.99', image: 'https://via.placeholder.com/300x400' },
  // { id: 5, name: 'Leather Jacket', price: '$89.99', image: 'https://via.placeholder.com/300x400' },
  // { id: 6, name: 'Sunglasses', price: '$19.99', image: 'https://via.placeholder.com/300x400' },
  // { id: 7, name: 'Sneakers', price: '$59.99', image: 'https://via.placeholder.com/300x400' },
  // { id: 8, name: 'Leather Jacket', price: '$89.99', image: 'https://via.placeholder.com/300x400' },
  // { id: 9, name: 'Sunglasses', price: '$19.99', image: 'https://via.placeholder.com/300x400' },
]);

const loading = ref(true); // Loading state
const intialloading = ref(true);
const loadMoreProducts = async () => {
  if (loading.value) return; // Prevent loading if already loading
  loading.value = true; // Set loading state

  page.value = page.value + 1;
  filterStore.selectedFilters.page = page.value;
  let filters = JSON.parse(JSON.stringify(filterStore.selectedFilters));
  filters.searchterm = searchQuery.value;
  let data = await ProductService.getProductList(
    URLService.getProducts,
    filters
  );

  if (data.status == 200) products.value.push(...data.data);
  else products.value.push(...[]);

  if (data.data.length < 16) {
    loading.value = false;
  } else {
    loading.value = false;
  }

  // // Simulate an API call
  // setTimeout(() => {
  //   // Here you would typically fetch more products from your API
  //   const newProducts = [
  //     { id: 7, name: 'Hat', price: '$14.99', image: 'https://via.placeholder.com/300x400' },
  //     { id: 8, name: 'Belt', price: '$24.99', image: 'https://via.placeholder.com/300x400' },
  //     { id: 9, name: 'Watch', price: '$89.99', image: 'https://via.placeholder.com/300x400' },
  //     { id: 10, name: 'Bag', price: '$39.99', image: 'https://via.placeholder.com/300x400' },
  //   ];

  //   products.value.push(...newProducts);
  //   loading.value = false; // Reset loading state
  //   filteredProducts.value = products.value; // Update filtered products
  // }, 1500); // Simulating network delay
};

onMounted(() => {
  page.value = 0;
  searchQuery.value = filterStore.search;
  reloadproducts();
});

const reloadproducts = async () => {
  products.value = [];
  page.value = 0;
  filterStore.selectedFilters.page = page.value;
  let filters = JSON.parse(JSON.stringify(filterStore.selectedFilters));
  filters.searchterm = searchQuery.value;
  let data = await ProductService.getProductList(
    URLService.getProducts,
    filters
  );
  if (data.status == 200) products.value = data.data;
  else products.value.push(...[]);

  intialloading.value = false;

  intialloading.value = false;
  let filtercolor = "";
  if (filterStore.selectedFilters.colors.length > 0) {
    filtercolor = filterStore.selectedFilters.colors[0];
  } else {
    filtercolor = "";
  }
  let updatedProducts = products.value.map((y) => {
    let updatedLines = y.lines.map((x) => {
      try {
        let k = JSON.parse(x.stock);
        var a = k.filter((x) => {
          return (
            (filterStore.search != '' && x.color?.toLowerCase().includes(filterStore.search.toLowerCase())) ||
            (filtercolor != "" &&
              x.color?.toLowerCase().includes(filtercolor.toLowerCase()))
          );
        });

        if (a.length > 0 && a[0].images?.length > 0) {
          let imageindex = y.images?.indexOf(a[0].images[0]);
          if (imageindex !== -1 && y.images.length > 1) {
            let firstimage = y.images[0];
            let replaceimage = y.images[imageindex];
            y.images[0] = replaceimage;
            y.images[imageindex] = firstimage;
          }
        }
      } catch (error) {
        console.log(error);
      }
      return x;
    });

    return { ...y, lines: updatedLines };
  });

  // Assign the updated products to `filteredProducts`
  filteredProducts.value = updatedProducts;

  // products.value.foreach((y) => {
  //   y.lines.filter((x) => {
  //     try {
  //       let k = JSON.parse(x.stock);
  //       var a = k.filter((x) => {
  //         return x.color.toLowerCase().includes(filterStore.search);
  //       });
  //       if (a.length > 0 && a[0].images && a[0].images.length > 0) {
  //         let imageindex = y?.images?.indexOf(a[0]?.images[0]);
  //         let firstimage = y?.images[0];
  //         let replaceimiage = y?.images[imageindex];
  //         y.images[0] = replaceimiage;
  //         y.images[imageindex] = firstimage;
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   });
  // });
  // filteredProducts.value = products.value;
  loading.value = false;
  filterStore.reGetata = false;
};

const filteredProducts = computed(() => {
  return products.value;
  // if (!searchQuery.value) return products.value;
  // return products.value.filter((product) =>
  //   product.name.toLowerCase().includes(searchQuery.value.toLowerCase())
  // );
});

const onAddToCollection = (product) => {
  console.log("Product added to collection:", product);
};

const triggerSearch = debounce(() => {
  if (searchQuery.value.length == 0) {
    filterStore.search = "";
    intialloading.value = true;
    reloadproducts();
  }
  if (searchQuery.value.length >= 3) {
    filterStore.search = searchQuery.value;
    intialloading.value = true;
    reloadproducts();
  }
}, 100);

watch(searchQuery, (newQuery) => {
  triggerSearch(newQuery);
});

watch(
  () => filterStore.reGetata,
  (newValue) => {
    if (newValue == true) {
      reloadproducts();
    }
  }
);
</script>
