<template>
  <div class="modal" v-if="isVisible" @click.self="closeModal">
    <div class="modal-content wrapper">
      <span
        class="close-btn"
        @click="closeModal"
        style="
          position: absolute;
          cursor: pointer;
          padding: 0.3rem;
          right: 2rem;
          top: 1.5rem;
          font-size: x-large;
        "
        >&times;</span
      >
      <h3>Add Filters</h3>
      <div
        ref="scrollableContainer"
        class="filter-scrollable"
        v-if="!showSelection"
      >
        <ul class="filter-options">
          <li
            style="margin-left: 10px; margin-right: 10px"
            @click="selectFilter('brands')"
            :class="{
              active:
                filterStore.selectedFilters.brands.length ||
                localFilters.brands.length,
            }"
          >
            Brands
          </li>
          <li
            style="margin-left: 10px; margin-right: 10px"
            @click="selectFilter('product')"
            :class="{
              active:
                filterStore.selectedFilters.product.length ||
                localFilters.product.length,
            }"
          >
            Products
          </li>
          <li
            style="margin-left: 10px; margin-right: 10px"
            @click="selectFilter('gender')"
            :class="{
              active:
                filterStore.selectedFilters.gender.length ||
                localFilters.gender.length,
            }"
          >
            Gender
          </li>
          <li
            style="margin-left: 10px; margin-right: 10px"
            @click="selectFilter('colors')"
            :class="{
              active:
                filterStore.selectedFilters.colors.length ||
                localFilters.colors.length,
            }"
          >
            Colors
          </li>
          <li
            style="margin-left: 10px; margin-right: 10px"
            @click="selectFilter('events')"
            :class="{
              active:
                filterStore.selectedFilters.events.length ||
                localFilters.events.length,
            }"
          >
            Events
          </li>
          <li
            style="margin-left: 10px; margin-right: 10px"
            @click="enablepricefilterAndFocus"
            :class="{
              active:
                filterStore.selectedFilters.price.min != '' ||
                filterStore.selectedFilters.price.max != '' ||
                localFilters.price.min != '' ||
                localFilters.price.max != '',
            }"
          >
            Price
          </li>
          <li
            style="
              display: flex;
              gap: 10px;
              margin-left: 10px;
              margin-right: 10px;
            "
            v-if="isenablepricefilter"
          >
            <input
              ref="minInput"
              class="form-control w-50"
              v-model="localFilters.price.min"
              style="float: left"
              placeholder="Min"
              type="number"
              @input="filterNumericInput"
            />
            <input
              class="form-control w-50"
              v-model="localFilters.price.max"
              placeholder="Max"
              type="number"
              @input="filterNumericInput"
            />
          </li>
        </ul>
      </div>
      <div v-else style="height: 100%">
        <FilterSelection
          :filterType="selectedFilter"
          :options="getOptions(selectedFilter)"
          :onSelect="handleSelection"
          :onCancel="cancelSelection"
          :tempselection="localFilters"
        />
      </div>
      <div class="modal-actions" v-if="!showSelection">
        <button @click="applyFilters" class="btn-apply" v-ripple>Apply</button>
        <button class="clear-filter" @click="cancelFilters">
          Clear Filters
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, defineProps, watch, onMounted, nextTick } from "vue";
import { useFilterStore } from "@/stores/filterstore.js";
import FilterSelection from "@/components/FilterSelection.vue";
import { Device } from "@capacitor/device";

const filterNumericInput = (event) => {
  const value = event.target.value;
  event.target.value = value.replace(/[^0-9]/g, ""); // Allow only numeric characters
};

const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
  closeModal: {
    type: Function,
    required: true,
  },
});
const filterStore = useFilterStore();

const showSelection = ref(false);
const selectedFilter = ref(null);
const localFilters = ref({
  brands: [],
  product: [],
  colors: [],
  events: [],
  gender: [],
  price: { min: "", max: "" },
});
const isenablepricefilter = ref(false);
const minInput = ref(null);
const scrollableContainer = ref(null);

const enablepricefilterAndFocus = () => {
  isenablepricefilter.value = !isenablepricefilter.value;

  // Use nextTick to ensure DOM updates before focusing
  nextTick(() => {
    if (minInput.value && scrollableContainer.value) {
      // Focus the input
      minInput.value.focus();

      // Scroll the container to the input element
      const container = scrollableContainer.value;
      const inputPosition = minInput.value.offsetTop - container.offsetTop;
      container.scrollTo({ top: inputPosition, behavior: "smooth" });
    }
  });
};

watch(
  () => props.isVisible,
  (newval) => {
    showSelection.value = false;
  }
);

// Store the previous filter selections for resetting if necessary
const previousFilters = ref({
  brands: [],
  product: [],
  colors: [],
  events: [],
  gender: [],
  price: { min: "", max: "" },
});

// Example options for each filter
const filterOptions = {
  Brand: ["Brand A", "Brand B", "Brand C"],
  Product: ["Product A", "Product B", "Product C"],
  Colors: ["Red", "Green", "Blue"],
  Events: ["Event A", "Event B", "Event C"],
  Gender: ["Men", "Women", "Unisex"],
};

const getmailfilters = () => {
  if (filterStore.mainFilters.value == undefined) {
    const itemStr = localStorage.getItem("commondata");
    filterStore.mainFilters.value = JSON.parse(itemStr)["value"];
  }
};

// Function to get options based on selected filter type
const getOptions = (filterType) => {
  if (filterType == "gender") return filterOptions.Gender;

  getmailfilters();
  var a = filterStore.mainFilters.value[filterType];
  return a || [];
};

// Function to handle filter selection
const handleSelection = (selected) => {
  const currentFilterType = selectedFilter.value.toLowerCase();

  // Merge the selected options with existing local filter state
  localFilters.value[currentFilterType] = selected;

  showSelection.value = false; // Close selection modal
};

// Function to select filter
const selectFilter = (filter) => {  
  // if (selectedFilter.value == "product") {
  //   a.forEach((element) => {
  //     if (element.name?.toLowerCase() == "clothing") {
  //       let clothall = [{ name: "All Clothing" },
  //       { name: "All Tops" },
  //       { name: "All Bottoms" },
  //       { name: "All Dresses" }
  //       ];
  //       let clomerge = clothall.concat(element.children);
  //       element.children = clomerge;
  //     }
  //     if (element.name?.toLowerCase() == "footwear") {
  //       let footall = [{ name: "All footwear" }
  //       ];
  //       let footmerge = footall.concat(element.children);
  //       element.children = footmerge;
  //     }
  //     if (element.name?.toLowerCase() == "accessories") {
  //       let accesall = [{ name: "All Bags" },
  //       { name: "All Headwear" },
  //       { name: "All Jewelry" }
  //       ];
  //       let accessmerge = accesall.concat(element.children);
  //       element.children = accessmerge;
  //     }
  //   });
  // }
  selectedFilter.value = filter;
  showSelection.value = true;
};

// Cancel selection and return to filter options
const cancelSelection = () => {
  showSelection.value = false;
};

const applyFilters = () => {
  filterStore.applyFilters(localFilters.value);
  props.closeModal();
};

// Cancel and clear the filter selections
const cancelFilters = () => {
  localFilters.value = { ...previousFilters.value }; // Reset local filters
  filterStore.clearFilters(); // Clear Pinia store filters
  props.closeModal(); // Close the modal
};

onMounted(() => {
  updateAppHeight(); // Set initial height
});

async function updateAppHeight() {
  const info = await Device.getInfo();
  let adjustedHeight = 0;
  if (info.platform == "ios") adjustedHeight = 550;
  else adjustedHeight = window.innerHeight - window.innerHeight * 0.18;
  document.documentElement.style.setProperty(
    "--app-height",
    `${adjustedHeight}px`
  );
}
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal-content {
  top: -10vh !important;
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  height: 550px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

h3 {
  text-align: center;
}

.filter-options {
  list-style: none;
  padding: 0;
}

.filter-options li {
  padding: 15px 20px;
  font-size: 1.2rem;
  font-weight: 500;
  background-color: white;
  color: black;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.filter-options li:hover {
  background-color: #f0f0f0;
  transform: scale(1.02);
}

.modal-actions {
  display: flex;
  flex-direction: column;
}

.modal-actions button {
  margin-bottom: 10px;
  border-radius: 50px;
}

button {
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  border: 1px solid black;
  background-color: white !important;
}

.btn-apply {
  background-color: black !important;
  color: white;
}

.btn-cancel {
  background-color: black;
  color: white;
}

button:hover {
  opacity: 0.8;
}

.filter-options li.active {
  background-color: black;
  /* Change background on hover */
  color: white;
  /* Change text color on hover */
}
.filter-scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  height: 60vh;
  max-height: 60vh; /* Adjust this to control scrollable area height */
}

.filter-scrollable::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}
.filter-scrollable::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}

.clear-filter {
  color: black;
}

.wrapper {
  /* height: var(--app-height); */
}

.wrapper::-webkit-scrollbar {
  display: none; /* Custom scrollbar height */
}

.wrapper::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
  display: none;
}
</style>
