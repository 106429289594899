<template>
  <div class="modal" v-if="isVisible" @click.self="closeModal">
    <div class="modal-content">
      <h4 class="mb-2 mt-0" style="text-align: center;">Customise your gift</h4>
      <div style="overflow-y: scroll">
        <div class="form-floating mb-2">
          <input
            type="text"
            id="fromName"
            class="form-control"
            v-model="gift.giftfrom"
            placeholder="Who's this gift from?"
            maxlength="30"
            required
          />
          <label for="fromName">Who's this gift from? (required)</label>
          <small class="text-muted">{{ gift.giftfrom.length }}/30</small>

        </div>

        <div class="form-floating mb-2">
          <input
            type="text"
            id="toName"
            class="form-control"
            v-model="gift.giftfor"
            maxlength="30"
            placeholder="Who's this gift for?"
            required
          />
          <label for="toName" class="form-label"
            >Who's this gift for? (required)</label
          >
          <small class="text-muted">{{ gift.giftfor.length }}/30</small>
        </div>

        <div class="mb-3">
          <label for="phone" class="form-label"
            >Recipient phone number (required)</label
          >
          <PhoneNumberInput
            :phoneid="'phone2'"
            v-model:phonenumber="phonenumber"
          />
          <!-- <PhoneNumberInput :phoneid="'phone2'" :phonenumber="gift.telephone"/> -->
          <!-- <button
          type="button"
          class="btn btn-secondary mt-2"
          @click="fillFromContacts"
        >
          📇 Fill from contacts
        </button> -->
        </div>

        <div class="mb-3">
          <label for="note" class="form-label">Add a note</label>
          <textarea
            id="note"
            class="form-control"
            v-model="gift.note"
            maxlength="300"
            rows="3"
            placeholder="Add a message for the recipient."
          ></textarea>
          <small class="text-muted">{{ gift.note.length }}/300</small>
        </div>
      </div>

      <div class="modal-actions" v-if="!showSelection">
        <button @click="cancelFilters" style="color: black">Cancel</button>
        <button
          @click="applyFilters"
          style="background-color: black !important; color: white"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue";
import PhoneNumberInput from "./PhoneNumberInput.vue";
const phonenumber = ref("");

const $emit = defineEmits(["saveGift"]);
const saveGift = () => {
  let giftData = {
    giftfrom: gift.value.giftfrom,
    giftfor: gift.value.giftfor,
    telephone: phonenumber.value,
    note: gift.value.note,
  };
  $emit("saveGift", giftData);
};

const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
  closeModal: {
    type: Function,
    required: true,
  },
});

const showSelection = ref(false);
const gift = ref({
  giftfrom: "",
  giftfor: "",
  telephone: "",
  note: "",
});

const applyFilters = async () => {
  await saveGift();
  props.closeModal();
};

const cancelFilters = () => {
  props.closeModal();
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal-content {
  top: -10vh !important;
  background: white;
  border-radius: 10px;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 550px;
}

h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-align: center;
}

.filter-options {
  list-style: none;
  padding: 0;
}

.filter-options li {
  padding: 15px 20px;
  font-size: 1.2rem;
  font-weight: 500;
  background-color: white;
  color: black;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.filter-options li:hover {
  background-color: #f0f0f0;
  transform: scale(1.02);
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

button {
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  border: 1px solid black;
  background-color: white !important;
}

.btn-apply {
  background-color: white;
  color: black;
}

.btn-cancel {
  background-color: black;
  color: white;
}

button:hover {
  opacity: 0.8;
}

.filter-options li.active {
  background-color: black;
  /* Change background on hover */
  color: white;
  /* Change text color on hover */
}

.form-floating {
  position: relative;
}

.form-floating input {
  height: 36px !important; /* Reduce the height */
  outline: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px; /* Adjust font size */
  line-height: 18px; /* Adjust line height */
  padding: 6px 8px; /* Reduce padding for smaller height */
  padding-top: 16px !important; /* Add space for the label */
}

.form-floating label {
  position: absolute;
  top: 8px; /* Adjust to fit smaller height */
  left: 12px; /* Align with input padding */
  font-size: 12px; /* Smaller label font size */
  color: #000000;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
  padding: 0 4px;
  z-index: 1;
}

.form-floating input:focus + label,
.form-floating input:not(:placeholder-shown) + label {
  top: 4px; /* Adjust position when floating */
  left: 8px; /* Match padding */
  font-size: 10px; /* Smaller floating font size */
  color: #333;
}

.form-floating input:focus {
  border-color: #007bff;
  box-shadow: 0 0 3px rgba(0, 123, 255, 0.25);
}

</style>
