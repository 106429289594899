<template>
  <div class="ms-4">
    <span
      style="
        font-size: 0.7rem;
        color: #969696;
        font-style: italic;
        padding-left: 5px;
      "
      >Free Standard Delivery for orders over LKR 15,000</span
    >

    <div
      v-for="(option, index) in deliveryOptions"
      :key="index"
      class="d-flex justify-content-between p-2 deliveroption"
      :class="{ selectedoption: modelValue === option }"
      @click="$emit('update:modelValue', option)"
    >
      <div>
        <b>{{ option.name }} </b><br />
        <small>{{ option.description }}</small>
      </div>
      <b>LKR {{ option.price }}</b>
    </div>
    <div
      class="d-flex justify-content-between p-2 mt-2 deliveroption"
      :class="{ selectedoption: modelValue === flashobj }"
      @click="openGeoLocationPicker"
      v-if="
        isgeoenabled != undefined &&
        isgeoenabled != null &&
        isgeoenabled != '' &&
        showgeolocation == true
      "
    >
      <!-- @click="$emit('update:modelValue', 'a')"
    > -->
      <div>
        <b>{{ flashobj.name }} </b>

        <span class="info-icon-container" @click.stop>
          <i class="fa fa-info-circle ml-2" style="cursor: pointer"></i>
          <span class="tooltip-text">
            <!-- Flash delivery is limited to a single vendor order within a 25km
            radius, and for larger packages, we may need to upgrade the delivery
            vehicle from a bike to a tuk tuk, which could result in additional
            charges. This will be informed before dispatch. -->

            Eligibility for Flash Delivery: <br /><br />
            <ul>
              <li class="mb-2">
                Flash delivery only applies to single-vendor orders within a 25km radius of that vendor.
              </li>
              <li class="mb-2">
                The store must be open for flash delivery orders to proceed.
              </li>
              <li class="mb-2">
                Product must be eligible for flash delivery. You can check if a product is NOT eligible, in 'My Cart' above the price OR on the product page below 'Return Policy'.
              </li>
              <li class="mb-2">
                Larger packages may require upgrading from a bike to a tuk tuk. Additional charges may apply and will be communicated before dispatch.
              </li>
            </ul>
          </span> </span
        ><br />
        <small
          v-if="
            !distanceerror &&
            enableflashdelivery != false &&
            shopclosed == false
          "
          >{{ flashobj.description }}</small
        >
        <small
          v-if="enableflashdelivery == false"
          style="color: red; line-height: 15px; display: block"
        >
          Oops, some item/s in your cart are not eligible for Flash delivery.
          Click 'i' icon above for more info</small
        >
        <small
          v-if="shopclosed == true"
          style="color: red; line-height: 15px; display: block"
        >
          Oops, looks like the store is closed at the moment Click 'i' icon
          above for more info</small
        >
        <small v-else style="color: red; line-height: 15px; display: block">{{
          distanceerror
        }}</small>
      </div>
      <b
        v-if="
          !distanceerror && enableflashdelivery != false && shopclosed == false
        "
        >LKR {{ flashobj.price }}</b
      >
    </div>
  </div>

  <GeoPickerModal
    :shopLocation="shopLocation"
    :isVisible="isModalVisible"
    @locationSelected="handleLocationSelection"
    @closeModal="closeModal"
  />
</template>

<script setup>
import { defineProps, ref, defineEmits, onMounted } from "vue";
import GeoPickerModal from "./GeoPickerModal.vue";
const emit = defineEmits(["updatedelivery"]);
const props = defineProps({
  deliveryOptions: Array,
  modelValue: Object,
  enableflashdelivery: Boolean,
  isgeoenabled: String,
  showgeolocation: Boolean,
  shopclosed: Boolean,
});

// State variables
const isModalVisible = ref(false);
const selectedLocation = ref(null);

// Shop Location (latitude and longitude)
const shopLocation = ref(null); // Replace with your shop's actual location

onMounted(() => {
  var shoparr = props.isgeoenabled?.split(",");
  if (shoparr != undefined && shoparr != null && shoparr != "")
    shopLocation.value = { lat: shoparr[0], lng: shoparr[1] };
});

// Open Geo Picker Modal
const openGeoLocationPicker = () => {
  if (props.shopclosed == true || props.enableflashdelivery == false)
    return;
  isModalVisible.value = true;
};
// Close Modal and Get Selected Location
const closeModal = async (location) => {
  isModalVisible.value = false;
  if (location) {
    selectedLocation.value = location;
    // distance.value = await calculateDrivingDistance(
    //   shopLocation,
    //   location
    // );
  }
};

// Use Google Distance Matrix API to calculate driving distance
// const calculateDrivingDistance = async (shop, customer) => {
//   const API_KEY = "AIzaSyBG1HicY2KuoLcuYNJvF0wDsGLR_LgXfPo"; // Replace with your API key
//   const endpoint = `https://maps.googleapis.com/maps/api/distancematrix/json?units=metric&origins=${shop.lat},${shop.lng}&destinations=${customer.lat},${customer.lng}&key=${API_KEY}`;

//   try {
//     const response = await fetch(endpoint);
//     const data = await response.json();
//     if (data.rows[0].elements[0].status === "OK") {
//       // Distance in kilometers
//       return data.rows[0].elements[0].distance.value / 1000;
//     } else {
//       console.error("Distance calculation failed:", data);
//       return null;
//     }
//   } catch (error) {
//     console.error("Error fetching distance:", error);
//     return null;
//   }
// };

const distanceerror = ref("");
const flashobj = ref({
  name: "Flash Delivery",
  price: "-",
  description: "Within 2 hours",
});
const handleLocationSelection = (obj) => {
  isModalVisible.value = false;
  if (obj.distance > 25) {
    distanceerror.value =
      "Oops, Flash delivery is only available for trips within 25km. Click 'i' icon above for more info";
  } else {
    distanceerror.value = "";
    let price = 0;
    let additionalkms = obj.distance - 5;
    if (additionalkms > 0) {
      price = (400 + additionalkms * (1000 / 100) * 6.5).toFixed(0);
    } else {
      price = 400;
    }
    flashobj.value = {
      name: "Flash Delivery",
      price: price,
      description: "Within 2 hours",
      loc: JSON.stringify(obj.loc),
    };
    emit("update:modelValue", flashobj.value);
  }
};
</script>

<style scoped>
.selectedoption {
  background-color: #000 !important;
  color: #fff !important;
}

.deliveroption {
  border-radius: 10px;
  background-color: #dcdcdc8a;
  padding: 10px !important;
}
.info-icon-container {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}

.tooltip-text {
  visibility: hidden;
  width: 300px;
  background-color: #333;
  color: #fff;
  text-align: left;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
}

.info-icon-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
</style>
